import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import AktualnoscApp from "./pages/atualnosci"; 
import Rozszerzenie from "./pages/aktualnosc"; // Corrected import

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aktualnosci" element={<AktualnoscApp />} />
          <Route path="/rozszeszenie/:id" element={<Rozszerzenie />} /> {/* Added :id */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
