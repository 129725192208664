import React from "react";
import { Link } from "react-router-dom";
import aktualnosciData from "./aktualnosciData";

const Aktualnosci2 = () => {
    return (
        <div>
            <br></br>
            <h1 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-center text-white">Aktualności</h1>
            <div className="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
                <div className="grid w-full grid-cols-1 gap-6 mx-auto lg:grid-cols-3">
                    {aktualnosciData.map((data, index) => (
                        <div className="p-6" key={index}>
                            <img className="object-cover object-center w-full h-48 lg:h-48 md:h-48 sm:h-48 mb-8 rounded-xl" src={data.image} alt="obrazek" />
                            <h1 className="mx-auto mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl text-white">{data.title}.</h1>
                            <p className="mx-auto text-base leading-relaxed text-gray-500">
                                {data.content.length > 200 ? `${data.content.substring(0, 200)}...` : data.content}
                            </p>
                            <div className="mt-4">
                                <Link to={`/rozszeszenie/${data.id}`} className="inline-flex items-center mt-4 font-semibold text-blue-600 lg:mb-0 hover:text-neutral-600" title="read more"> Więcej » </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Aktualnosci2;
