import React from "react";

const DescriptionWithImage = ({ imageSrc, altText, title, description, reverse }) => {
  return (
    <div className={`flex flex-wrap text-white items-center mt-20 text-left ${reverse ? "text-center md:text-right" : "text-center md:text-left"}`}>
      <div className={`w-full md:w-3/5 lg:w-1/2 px-4 ${reverse ? "md:order-last lg:pr-12" : "lg:pl-12"}`}>
        <img src={imageSrc} alt={altText} className="object-cover h-48 w-full md:h-96 md:w-auto mx-auto mb-4 rounded-xl mt-8 md:mt-0" />
      </div>
      <div className={`w-full text-white md:w-2/5 lg:w-1/2 px-4 ${reverse ? "md:order-first lg:pl-12" : "lg:pr-12"}`}>
        <h3 className="font-bold text-white mt-8 text-xl md:mt-0 sm:text-2xl">
          {title}
        </h3>
        <p className="sm:text-lg mt-6 text-white">
          {description}
        </p>
      </div>
    </div>
  );
};

export default DescriptionWithImage;
