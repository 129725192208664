import React from "react";
import { Carousel } from "@material-tailwind/react";
import zdjecie1 from "./zdjecia/o-nas/naszteatr1.jpg";
import zdjecie2 from "./zdjecia/o-nas/naszteatr3.jpg";
import zdjecie3 from "./zdjecia/o-nas/naszteatr5.jpg";

const Naszteatr = () => {
  return (
    <div className="bg-{#3E3636}">
      <br />
      <h1 className="text-white text-2xl sm:text-3xl md:text-5xl lg:text-5xl font-extrabold text-center">
       Nasze cienie

      </h1>
      <br />

      <div className="max-w-lg lg:max-w-4xl mx-auto">
        <Carousel
          className="rounded-xl"
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          <img
            src={zdjecie1}
            alt="Zdjęcie 1"
            className="h-full w-full object-cover"
          />
          <img
            src={zdjecie2}
            alt="Zdjęcie 2"
            className="h-full w-full object-cover"
          />
          <img
            src={zdjecie3}
            alt="Zdjęcie 3"
            className="h-full w-full object-cover"
          />
        </Carousel>
      </div>

      <p className="text-white text-center md:px-40 sm:px-5 sm:text-1xl md:text-2xl">
        Techniką Teatru Cieni zajmujemy się od ponad 20 lat. Wszystko zaczęło się od obejrzenia na kasecie VHS jednego z odcinków programu Jima Hensona- twórcy Muppet Show, w którym pokazywał on lalkarzy z całego świata. Bohaterem programu był Richard Bradshaw - artysta zajmujący się teatrem cieni. Ten prosty i dowcipny teatr Richarda zaczarował nas. W podobnej technice zrobiliśmy spektakle „ Romans Perlimplina i Belisy” i „Okienko”. Później poznawaliśmy cienie i używaliśmy nowych technik. Powstały spektakle „Sen Rybaka”, „Podróż”,„ Słoń Ptak i...”, „Pan Maluśkiewicz”. W naszym najnowszej sztuce „Pieśń rzeki” wcześniej używane techniki teatru cieni i światła wzbogaciliśmy jeszcze o animację oraz przetworzenie zdjęć fotograficznych.
      </p>
      <br />
      <br />
    </div>
  );
};

export default Naszteatr;
