const TABLE_HEAD = ["Data", "Spektakl", "Gdzie", "Zarezerwuj"];

const TABLE_ROWS = [
  {
    name: "10",
    miesiac: "sierpnia",
    projekt: "w ramach projektu Teatr w Ogrodzie",
    spetakl: "Cudowni mężczyźni z korbką (Walny- Teatr) | 19:00",
    date: "Teatr Łąstek Supraśl",
    link: "",
  },
  {
    name: "10",
    miesiac: "sierpnia",
    projekt: "w ramach projektu Teatr w Ogrodzie",
    spetakl: "Matecznik | 19:40",
    date: "Teatr Łąstek Supraśl",
    link: "",
  },
  {
    name: "16",
    miesiac: "sierpnia",
    projekt: "",
    spetakl: "warsztaty śpiewu tradycyjnego | 16:00",
    date: "Gminny Ośrodek Kultury w Michałowie",
    link: "https://app.evenea.pl/event/rzekatradycjimichalowo/",
  },
  {
    name: "17",
    miesiac: "sierpnia",
    projekt: "",
    spetakl: "warsztaty śpiewu tradycyjnego | 16:00",
    date: "Gminny Ośrodek Kultury w Michałowie",
    link: "https://app.evenea.pl/event/rzekatradycjigrodek/",
  },
  {
    name: "17",
    miesiac: "sierpnia",
    projekt: "",
    spetakl: "Warsztaty plecenia wianków | 18:00",
    date: "zejście do rzeki przy Amfiteatrze Miejskim w Michałowie",
    link: "",
  },
  {
    name: "17",
    miesiac: "sierpnia",
    projekt: "",
    spetakl: "Pieśń rzeki | 20:30",
    date: "zejście do rzeki przy Amfiteatrze Miejskim w Michałowie",
    link: "",
  },
  {
    name: "18",
    miesiac: "sierpnia",
    projekt: "",
    spetakl: "Warsztaty plecenia wianków  | 16:00",
    date: "nad rzeką za Centrum Kultury w Gródku",
    link: "",
  },
  {
    name: "18",
    miesiac: "sierpnia",
    projekt: "",
    spetakl: "warsztaty śpiewu tradycyjnego | 20:30",
    date: "nad rzeką za Centrum Kultury w Gródku",
    link: "",
  },
];

export { TABLE_HEAD, TABLE_ROWS };
