import zdjecie1 from "./zdjecia/spetakle/spetakle-matecznik.jpg";
import zdjecie2 from "./zdjecia/spetakle/spetakle-rzeki.jpg";
import zdjecie3 from "./zdjecia/spetakle/spetakle-bomba.jpg";
import zdjecie4 from "./zdjecia/spetakle/spetakle-posterunkowa.jpg";
import zdjecie5 from "./zdjecia/spetakle/spetakle-maluskiewicz.jpg";

const spetakleData = [
    {
        image: zdjecie1,
        title: "Matecznik",
        content: "Główną inspiracją do powstania widowiska pt. „Matecznik” stanowi wydany w 1984 roku „album fotograficznym” Wiktora Wołkowa pt.: „Rzeka, droga, płoty, krowy, konie, stogi, wrony, bociany, drzewa, krzyże”. Wydawnictwo zrealizowało trzech wybitnych artystów: Wiktor Wołkow – autor zdjęć, Edward Redliński – autor zamieszczonych opowieści oraz Andrzej Strumiłło, który stworzył koncepcję oraz opracował ją graficznie. Zamieszczone w albumie zdjęcia inspirują do stawiania pytań: o los człowieka; o indywidualność; o przyjaźń; o istnienie smutku na świecie.",
        aktorzy: [
            { role: "Scenariusz i reżyseria", name: "Krzysztof Zemło" },
            { role: "Scenografia", name: "Ewa Zemło" },    ]
    },
    {
        image: zdjecie2,
        title: "Pieśni Rzeki",
        content: "„PIEŚŃ RZEKI” Inspiracją do powstania widowiska były polskie ballady ludowe. Na ich bazie stworzyliśmy teatrodyski - inscenizacje pieśni realizowane w różnych technikach teatru cieni i światła. Ułożyły się one w opowieść - teatralną balladę o miłości i śmierci . Ważnym był dobór utworów. Elementem wspólnym dla wszystkich był motyw rzeki, wody, cieczy. Miłość jest bowiem nurtem, który nas porywa, czy tego chcemy czy nie, i nie wiadomo gdzie nas zaniesie",
        aktorzy: [
            { role: "Scenariusz i reżyseria", name: "Krzysztof Zemło" },
            { role: "Scenografia", name: "Ewa Zemło" },
            { role: "Kompozycje i śpiew", name: "Anna Broda" }        ]

    },
    {
        image: zdjecie3,
        title: "Dla mnie Bomba!!!",
        content: "Dla mnie bomba!!!” - to spektakl laboratorium. Główny bohater – naukowiec przy pomocy różnorodnych technik teatralnych opowiada w nim swoją historię. Otóż … pewnego razu w jego pracowni pojawili się dziwni goście. Bardzo interesowali się doświadczeniami, które robił i zadawali mu wiele pytań. Jak się później okazało wiedzę, którą w ten sposób zdobyli, wykorzystali do zrobienia najstraszliwszych na świecie bomb. Naukowiec, gdy się o tym dowiedział postanowił podkraść się do magazynów i te bomby przerobić … Jest to spektakl o tym, że bardzo dużo zależy od małych, niewidocznych atomów i o tym, że to, czego nie widać często jest ważniejsze od widzialnego.",
        aktorzy: [
            { role: "Scenariusz i reżyseria", name: "Krzysztof Zemło" },
            { role: "Scenografia", name: "Ewa Zemło" },    ]
    },
    {
        image: zdjecie4,
        title: "Dom Posterunkowa 1 Supraśl",
        content: "To opowieść o mieszkańcach i historii domu, w którym mieści się teatr.Przez ponad 100 lat nazbierało się wiele do opowiedzenia….Ewa i Krzysztof Zemłowie – twórcy i współwłaściciele domu podjęli się próby ponownego znalezienia tożsamości miejsca, w którym żyją i tworzą. Opowiedzenia o świecie, którego już nie ma, w otoczeniu ścian, podłóg i przedmiotów, które to wszystko pamiętają.Ten spektakl to wykreowane z teatralnej – ulotnej tkanki spotkanie przeszłości z teraźniejszością. Pokazujący niezwykłe życie zwykłych ludzi z supraskiego domu przy ul. Posterunkowej 1.",
        aktorzy: [
            { role: "Scenariusz i reżyseria", name: "Krzysztof Zemło" },
            { role: "Scenografia", name: "Ewa Zemło" },    ]

    },
    {
        image: zdjecie5,
        title: "Pan Maluśkiewicz",
        content: "Nasz główny bohater - Pan Maluśkiewicz chce spełnić swoje największe marzenie – chce zobaczyć wieloryba. Wyrusza w tym celu w największą w swoim życiu podróż. Spektakl jest opowieścią o marzeniach, które pozwalają przezwyciężyć nasze ograniczenia i umożliwiają przekroczenie ram naszego małego świata. Zastosowane w widowisku j różne techniki teatru cienia i światła pozwalają wykreować świat malarski, pełen obrazów i kolorów. Naszym przewodnikiem po nim jest sam – Rene Magritte – słynny malarz surrealista.",
        aktorzy: []
    }
];

export default spetakleData;
