import React from "react";
import Navbar from "../components/Navbar";
import Oteatrze from "../components/oteatrze";
import Aktualnosci from "../components/aktualnosci";
import Repertuar from "../components/repertuar";
import Stopka from "../components/stopka";
import Spetakle from "../components/spetakle";
import Naszteatr from "../components/naszteatr";
import Aktualnosci2 from "../components/aktualnosci2";
import AktualnosciMini from "../components/aktualnoscimini";

function Home() {
  return (
    <div>
      <Navbar/>
      <div style={{ paddingTop: '96px' }}>
        <section id="oteatrze" style={{ paddingTop: '96px', marginTop: '-96px' }}>
          <Oteatrze/>
        </section>
        <br/>
        <br/>
        <br/>
        <section id="repertuar" style={{ paddingTop: '96px', marginTop: '-96px' }}>
          <Repertuar />
        </section>
        <br/>
        <section id="aktualnosci" style={{ paddingTop: '96px', marginTop: '-96px' }}>
          <AktualnosciMini/>
        </section>
        <br/>
        <section id="spetakle" style={{ paddingTop: '96px', marginTop: '-96px' }}>
          <Spetakle />
        </section>
        <section id="warsztaty" style={{ paddingTop: '96px', marginTop: '-96px' }}>
          <Naszteatr />
        </section>
        <br/>
        <br/>
        <Stopka/>
      </div>
    </div>
  );
}

export default Home;
