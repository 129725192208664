import pieImage from './zdjecia/piesPLKT.jpg';
import ogorek from './zdjecia/sezonnieogorek.JPG';
import piesni1 from './zdjecia/piesnirzekifolder/piesni.jpg';
import piesni2 from './zdjecia/piesnirzekifolder/piesni2.jpg';
import piesni3 from './zdjecia/piesnirzekifolder/Julita.jpg';
import piesni4 from './zdjecia/piesnirzekifolder/Krzysztof.jpg';
import piesni5 from './zdjecia/piesnirzekifolder/aniabroda.jpg';
import piesni6 from './zdjecia/piesnirzekifolder/emilia.jpg';
import piesni7 from './zdjecia/piesnirzekifolder/ewa.jpg';
import piesni8 from './zdjecia/piesnirzekifolder/przemek.jpg';
import ogrod from './zdjecia/teatr-w-ogrodzie.jpg'

const aktualnosciData = [
  {
    id: 3,
    image: ogrod,
    title: "teatr w ogrodzie",
    content: "",
    date: "lipiec 28",
    carouselImages: [
      { src: ogrod, alt: "Image 1" }
    ]
  },
  {
    id: 1,
    image: pieImage,
    title: "Pieśni Rzeki - rzeka tradycji!",
    content: "",
    carouselImages: [
      { src: pieImage, alt: "Image 1" },
      { src: piesni1, alt: "Image 2" },
      { src: piesni2, alt: "Image 3" },
      { src: piesni3, alt: "Image 4" },
      { src: piesni4, alt: "Image 5" },
      { src: piesni5, alt: "Image 6" },
      { src: piesni6, alt: "Image 7" },
      { src: piesni7, alt: "Image 8" },
      { src: piesni8, alt: "Image 9" }
    ]
  },
  {
    id: 2,
    image: ogorek,
    title: "Teatralny Sezon Nieogórkowy",
    content: "Latem, kiedy większość teatrów zawiesza swoje funkcjonowanie, my działamy w najlepsze i zapraszamy państwa do Teatru Łątek! Na naszej scenie będą pokazywane 4 spektakle z naszego repertuaru: [...]",
    date: "Styczeń 28",
    carouselImages: [
      { src: ogorek, alt: "Image 1" }
    ]
  },
  
];

export default aktualnosciData;
