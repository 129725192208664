import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logo from './loho.png';

const Navbar = () => {
    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav);
    };

    return (
        <div className="w-full bg-black fixed top-0 left-0 z-50">
            <div className="bg-black flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
                <img src={logo} className="text-3xl font-bold h-24 w-24" />
                <ul className="hidden md:flex">
                    <li className="p-4"><a href="/">STRONA GŁÓWNA</a></li>
                    <li className="p-4"><a href="#repertuar">REPERTUAR</a></li>
                    <li className="p-4"><a href="#aktualnosci">AKTUALNOSCI</a></li>
                    <li className="p-4"><a href="#spetakle">SPETAKLE</a></li>
                    <li className="p-4"><a href="#warsztaty">WARSZTATY</a></li>
                </ul>
                <div onClick={handleNav} className='block md:hidden'>
                    {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
                </div>
                <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'fixed left-[-100%]'}>
                    <h1 className="w-full text-3xl font-bold m-4">TEATR LĄTEK</h1>
                    <ul className="pt-4">
                        <li className="p-4 border-b border-gray-600"><a href="/">STRONA GŁÓWNA</a></li>
                        <li className="p-4 border-b border-gray-600"><a href="#repertuar">REPERTUAR</a></li>
                        <li className="p-4 border-b border-gray-600"><a href="#aktualnosci">AKTUALNOSCI</a></li>
                        <li className="p-4 border-b border-gray-600"><a href="#spetakle">SPETAKLE</a></li>
                        <li className="p-4 border-b border-gray-600"><a href="#warsztaty">WARSZTATY</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
