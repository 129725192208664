import React from "react";
import Navbar from "../components/Navbar";
import Stopka from "../components/stopka";
import Aktualnosci2 from "../components/aktualnosci2";

function AktualnoscApp() {
    return (
      <div>
          <Navbar></Navbar> 
          <Aktualnosci2></Aktualnosci2>
          <Stopka></Stopka>
      </div>
    );
}

export default AktualnoscApp;
