import React, { useState } from "react";
import spetakleData from "./spetakleData";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

const Spetakle = () => {
  return (
    <div className="mx-auto px-4 sm:px-8 lg:px-16 xl:px-40 py-8" style={{ backgroundColor: "#202060" }}>
      <h1 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-center text-white">
        Spetakle
      </h1>
      <br />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
        {spetakleData.map((data, index) => (
          <CardComponent key={index} data={data} />
        ))}
      </div>
    </div>
  );
};

const CardComponent = ({ data }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Card
        shadow={false}
        className="relative h-[26.67rem] w-full items-center justify-center overflow-hidden text-center mx-auto mb-6 sm:mb-0"
        onClick={handleOpen}
      >
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center"
          style={{ backgroundImage: `url(${data.image})` }}
        >
          <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
        </CardHeader>
        <CardBody className="relative flex flex-col items-center justify-end h-full text-center text-white py-10 px-4 md:px-8">
          <div className="absolute bottom-0 w-full flex justify-center p-4">
            <div className="text-center">
              <Typography
                variant="h2"
                color="white"
                className="mb-2 text-lg font-medium leading-[1.5]"
              ></Typography>
              <Typography variant="h5" className="text-gray-400">
                {data.title}
              </Typography>
            </div>
          </div>
        </CardBody>
      </Card>

      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>{data.title}</DialogHeader>
        <DialogBody className="max-h-[80vh] overflow-y-auto">
          <img className="object-cover object-center w-full h-48 lg:h-48 md:h-48 sm:h-48 mb-8 rounded-xl" src={data.image} alt="obrazek" />
          {data.content}
          {data.aktorzy && data.aktorzy.length > 0 && (
            <div className="mt-4">
              <Typography variant="h6">Aktorzy:</Typography>
              <ul>
                {data.aktorzy.map((aktor, index) => (
                  <li key={index}>
                    <span className="font-bold">{aktor.role}</span>{aktor.role ? ": " : ""}{aktor.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </DialogBody>
        <DialogFooter className="flex justify-center">
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
          >
            <span>Anuluj</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Spetakle;
