import React from "react";
import Navbar from "../components/Navbar";
import Aktualnosci2 from "../components/aktualnosci2";
import Stopka from "../components/stopka";
import Rozszerzenie from "../components/rozszeszenie";
function AktualnoscAppRozszeszenie() {
    return (
      <div>
          <Navbar /> 
          <Rozszerzenie/>
          <Stopka />
      </div>
    );
}
  
  export default AktualnoscAppRozszeszenie;
  