import React from "react";
import logo from './loho.png'

const Stopka = () => {
    return(
        <footer className="bg-black rounded-lg text-white">
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-24 w-24" alt="Flowbite Logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Teatr Łątek</span>
                    </a>
                    <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                        <p className="mb-2">Email: <a href="mailto:kontakt@teatrlatek.pl" className="hover:underline">zemlo@wp.pl</a></p>
                        <p className="mb-2">Telefon: <a href="tel:+48503728429" className="hover:underline">+48 503 728 429</a></p>
                        <p className="mb-2">Adres: Supraśl, Poserukowa 1/4</p>
                        <p className="mb-2">Facebook: <a href="https://www.facebook.com/teatrlatek/" className="hover:underline">Teatr Łątek</a></p>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 Teatr Łątek. Wszelkie prawa zastrzeżone.</span>
            </div>
        </footer>
    )
};

export default Stopka;
